import React from 'react';
import Navbar from './Components/Navbar/Navbar';
import './App.css';

function App() {
  return (
    <div className="App">
    
    <Navbar/>


    
    </div>
  );
}

export default App;