export const MenuItems = [
{
    title: "Home",
    url: "#",
    cName: "nav-links"
},

{
    title: "Services",
    url: "#",
    cName: "nav-links"
},
{
    title: "contact Us",
    url: "#",
    cName: "nav-links"
},
{
    title: "Terms and Conditon",
    url: "#",
    cName: "nav-links"
},
{
    title: "About Us",
    url: "#",
    cName: "nav-links"
},
{
    title: "Our Products",
    url: "#",
    cName: "nav-links-mobile"
}, 

{
    title: "Sign Up",
    url: "#",
    cName: "nav-links-mobile"
}

]
